import React, { memo, useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DRAW_TYPE, originSnapShot } from './constant'
import ToolBar from './ToolBar'
import FabricEditor from './FabricEditor'
import { useUI } from '../../contexts/UIContext'
import { useFullscreen } from '../../contexts/screen-context.js'

export const FabricContext = React.createContext()

const MemoToolBar = memo(ToolBar)
const MemoFabricEditor = memo(FabricEditor)

const EditPage = () => {
  const { projectId } = useParams()
  const drawCanvas = useRef(null)

  const [originImage, setOriginImage] = useState(null) // background image
  const [project, setProject] = useState(null)
  const [drawType, setDrawType] = useState(DRAW_TYPE.NORMAL)
  const [penWidth, setPenWidth] = useState(2)
  const [lassos, setLassos] = useState([]) // polygon control points
  const [activeIndex, setActiveIndex] = useState({ lassoIndex: -1, pointIndex: -1 }) // current polygon element index

  const [snapShots, setSnapShots] = useState(originSnapShot) // fabric snapshots for current inpaint
  const [snapShotsID, setSnapShotsID] = useState(0)
  const [inpaintSnapShots, setInpaintSnapShots] = useState(null) // snapshots for each inpaint
  const [inpaintSnapShotsID, setInpaintSnapShotsID] = useState(0)

  const [isLoading, setIsLoading] = useState(false) // inpaint process
  const [showOriginImage, setShowOriginImage] = useState(false)
  const { setIsFullscreen } = useFullscreen()
  const { setShowSidebar } = useUI()

  useEffect(() => {
    setShowSidebar(false)
    setIsFullscreen(true)
    const projectData = localStorage.getItem(projectId)
    setProject(projectData)
  }, [setShowSidebar, setIsFullscreen, projectId])

  return (
    <FabricContext.Provider
      value={{
        drawCanvas,
        originImage,
        setOriginImage,
        drawType,
        setDrawType,
        penWidth,
        setPenWidth,
        lassos,
        setLassos,
        activeIndex,
        setActiveIndex,
        snapShots,
        setSnapShots,
        snapShotsID,
        setSnapShotsID,
        inpaintSnapShots,
        setInpaintSnapShots,
        inpaintSnapShotsID,
        setInpaintSnapShotsID,
        isLoading,
        setIsLoading,
        showOriginImage,
        setShowOriginImage,
        project,
      }}
    >
      <MemoFabricEditor />
      <MemoToolBar />
    </FabricContext.Provider>
  )
}

export default EditPage
