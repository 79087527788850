import React from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col } from 'react-bootstrap'
import './pencil-dropdown.css'

PencilOptionsDropdown.propTypes = {
  options: PropTypes.node.isRequired,
  onOptionsChange: PropTypes.func.isRequired,
}

export function PencilOptionsDropdown({ options, onOptionsChange }) {
  return (
    <div className="pencilBar pencilOptionsDropdown">
      <Form>
        <Row>
          <Col xs={6}>
            <Form.Group controlId="color" className="d-flex">
              <Form.Label>Color</Form.Label>
              <div className="colorCircle">
                <input
                  type="color"
                  value={options.color}
                  onChange={(e) => onOptionsChange({ ...options, color: e.target.value })}
                  className="circleColorInput"
                />
              </div>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="size" className="d-flex align-items-center">
              <Form.Label>Stroke Size: {options.size}px</Form.Label>
              <div className="size-slider-container">
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={options.size}
                  onChange={(e) => onOptionsChange({ ...options, size: parseInt(e.target.value, 10) })}
                  className="size-slider"
                />
                <div className="size-preview-container">
                  <span
                    className="size-preview-inner"
                    style={{ width: `${options.size}px`, height: `${options.size}px` }}
                  ></span>
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="opacity">
              <Form.Label>Opacity: {Math.round(options.opacity * 100)}%</Form.Label>
              <Form.Control
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={options.opacity}
                onChange={(e) => onOptionsChange({ ...options, opacity: parseFloat(e.target.value) })}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="thinning">
              <Form.Label>Pressure Sensitivity: {options.thinning}</Form.Label>
              <Form.Control
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={options.thinning}
                onChange={(e) => onOptionsChange({ ...options, thinning: parseFloat(e.target.value) })}
              />
            </Form.Group>
            <Form.Group controlId="smoothing">
              <Form.Label>Smoothing: {options.smoothing}</Form.Label>
              <Form.Control
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={options.smoothing}
                onChange={(e) => onOptionsChange({ ...options, smoothing: parseFloat(e.target.value) })}
              />
            </Form.Group>
            <Form.Group controlId="streamline">
              <Form.Label>Streamline: {options.streamline}</Form.Label>
              <Form.Control
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={options.streamline}
                onChange={(e) => onOptionsChange({ ...options, streamline: parseFloat(e.target.value) })}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
