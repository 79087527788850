import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import {
  beforeHomeImg,
  afterHomeImg,
  beforeHomeImg1,
  afterHomeImg1,
  afterHomeImg2,
  afterHomeImg3,
  beforeHomeImg3,
} from '../../assets'
import './styles.css'

var videoSources = ['videos/ov.mp4', 'videos/upload.mp4']

var images = [
  { before: beforeHomeImg, after: afterHomeImg },
  { before: afterHomeImg1, after: beforeHomeImg1 },
  { before: afterHomeImg2, after: beforeHomeImg1 },
  { before: beforeHomeImg3, after: afterHomeImg3 },
]

const HomePage = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextVideo = () => {
    setCurrentVideoIndex((currentVideoIndex + 1) % videoSources.length) // Loop back to first
  }

  const prevVideo = () => {
    setCurrentVideoIndex((currentVideoIndex - 1 + videoSources.length) % videoSources.length) // Loop back to last
  }

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length) // cycle through images
  }

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length) // cycle back through images
  }

  // Current images based on currentIndex
  const { before: beforeHomeImg, after: afterHomeImg } = images[currentIndex]

  return (
    <div>
      <div className="overview-container">
        <div className="video-container">
          <video
            key={videoSources[currentVideoIndex]} // Important for React to re-render on source change
            autoPlay
            loop
            muted
            playsInline
            style={{ width: '100%', height: 'auto', borderRadius: '15px' }} // Inline styles for rounded corners
          >
            <source src={videoSources[currentVideoIndex]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button className="video-nav" onClick={prevVideo}>
            <i className="fas fa-chevron-left"></i>
          </button>
          <button className="video-nav" onClick={nextVideo}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
        <hr className="horizontal-bar" />
        <div className="flex mt-16 justify-between">
          <div className="w-2/5">
            <FontAwesomeIcon icon={faWandMagicSparkles} className="text-3xl text-sky-400" />
            <span> </span>
            <span className="text-4xl text-sky-400 font-mono font-semibold">Magic Inpaint</span>
            <p className="text-xl font-light mt-10">
              Utilize AI-driven automation to generate novel design variations or manually fine-tune your creations.
            </p>
            <Link to="/edit">
              <button className="mt-10 rounded-full px-6 py-2 bg-sky-400 hover:bg-sky-300 text-white">
                Get Started
              </button>
            </Link>
          </div>
          <div className="w-5/5">
            <div className="relative shadow-2xl border-2 rounded-xl overflow-hidden">
              {/* Previous Button */}
              <button
                onClick={prevImage}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-transparent text-gray-800 text-3xl"
                style={{ outline: 'none', border: 'none' }}
              >
                <FaArrowLeft />
              </button>
              <div>
                <img src={beforeHomeImg} alt="before" style={{ width: 650, maxWidth: 650 }} />
              </div>
              <div className="absolute top-0 left-0">
                <div className="resize-container"></div>
                <div className="resize-save">
                  <img src={afterHomeImg} alt="after" style={{ maxWidth: '650px', width: '650px' }} />
                </div>
                <span className="resize-icon"></span>
              </div>
              {/* Next Button */}
              <button
                onClick={nextImage}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-transparent text-gray-800 text-3xl"
                style={{ outline: 'none', border: 'none' }}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
        <hr className="horizontal-bar" />
        <FileHandlingSection />
        <FavoritesSection />
        <ProfileManagementSection />
        <DesignGenerationSection />
        <PartSelectionSection />
        <VisualizationExportSection />
      </div>
    </div>
  )
}

const FavoritesSection = () => (
  <div className="section">
    <div className="illustration">
      <div className="illustration-placeholder">Favorites Illustration</div>
    </div>
    <div className="content">
      <h2>Favorites</h2>
      <p>Bookmark and collate your preferred designs, optimizing the selection workflow.</p>
    </div>
  </div>
)

const ProfileManagementSection = () => (
  <div className="section">
    <div className="content">
      <h2>Profile Management</h2>
      <p>Manage your account settings and align the platform with your design aspirations and preferences.</p>
    </div>
    <div className="illustration">
      <div className="illustration-placeholder">Profile Management Illustration</div>
    </div>
  </div>
)

const DesignGenerationSection = () => (
  <div className="section">
    <div className="illustration">
      <div className="illustration-placeholder">Design Generation Illustration</div>
    </div>
    <div className="content">
      <h2>Design Generation</h2>
      <p>Utilize AI-driven automation to generate novel design variations or manually fine-tune your creations.</p>
    </div>
  </div>
)

const PartSelectionSection = () => (
  <div className="section">
    <div className="content">
      <h2>Part Selection</h2>
      <p>Engage in detailed customization down to individual components of your automotive designs.</p>
    </div>
    <div className="illustration">
      <div className="illustration-placeholder">Part Selection Illustration</div>
    </div>
  </div>
)

const VisualizationExportSection = () => (
  <div className="section">
    <div className="illustration">
      <div className="illustration-placeholder">3D Visualization Illustration</div>
    </div>
    <div className="content">
      <h2>3D Visualization and Export</h2>
      <p>
        Transform 2D concepts into immersive 3D models with options for further refinement in advanced software like
        Blender.
      </p>
    </div>
  </div>
)

const FileHandlingSection = () => (
  <div className="section">
    <div className="content">
      <h2>File Handling</h2>
      <p>Manage an array of file formats and ensure smooth transitions between different stages of design.</p>
    </div>
    <div className="illustration">
      <div className="illustration-placeholder">File Handling Illustration</div>
    </div>
  </div>
)

export default HomePage
