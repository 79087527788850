export type SelectedElementType = ElementType & {
  xOffsets?: number[]
  yOffsets?: number[]
  offsetX?: number
  offsetY?: number
}

export interface ExtendedElementType extends ElementType {
  xOffsets?: number[]
  yOffsets?: number[]
}

export type ElementType = {
  id: number
  x1: number
  y1: number
  x2: number
  y2: number
  type: ToolsType
  // TODO: add type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roughElement?: any
  offsetX?: number
  offsetY?: number
  position?: string | null
  points?: { x: number; y: number }[]
  text?: string
  size?: number
  opacity?: number
  color?: string
  thinning?: any
  smoothing?: any
  streamline?: any
  pressure?: any
}

export type ActionsType = 'writing' | 'drawing' | 'moving' | 'panning' | 'resizing' | 'upload' | 'none'

export const Tools = {
  upload: 'upload',
  pan: 'pan',
  selection: 'selection',
  rectangle: 'rectangle',
  line: 'line',
  pencil: 'pencil',
  text: 'text',
}

export type ToolsType = (typeof Tools)[keyof typeof Tools]

export type PointType = { x: number; y: number }
