import React from 'react'
import styled from '@emotion/styled'

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0; // Optional: Add background color
  overflow: hidden;
  margin: 0;
  padding: 0;
`

const SvgProgressBar = styled.svg`
  width: 100px; // Adjust size as needed
  height: 100px;
`

const Loading = () => {
  return (
    <LoadingWrapper>
      <SvgProgressBar viewBox="0 0 50 50">
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke="#3498db"
          strokeWidth="4"
          strokeDasharray="31.4 31.4"
          strokeLinecap="round"
          transform="rotate(-90 25 25)"
        >
          <animate attributeName="stroke-dashoffset" values="31.4;0" dur="1.5s" repeatCount="indefinite" />
        </circle>
      </SvgProgressBar>
    </LoadingWrapper>
  )
}

export default Loading
