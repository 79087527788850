import React, { useState } from 'react'
import { Modal, Input, Button } from 'antd'
import promptSuggestions from '../data/wheel_prompts.json'
/* eslint-disable react/prop-types */
const WheelMaskOptions = ({ visible, onClose, onGenerate }) => {
  const [wheelMaskPrompt, setWheelMaskPrompt] = useState('')
  const [currentPromptPage, setCurrentPromptPage] = useState(1)
  const promptsPerPage = 5

  const startIndex = (currentPromptPage - 1) * promptsPerPage
  const endIndex = startIndex + promptsPerPage
  const currentPrompts = promptSuggestions.prompts.slice(startIndex, endIndex)

  return (
    <Modal
      title="Wheel Options"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} className="bg-gray-300 hover:bg-gray-400 rounded">
          Cancel
        </Button>,
        <Button
          key="generate"
          type="primary"
          onClick={() => onGenerate(wheelMaskPrompt)}
          disabled={!wheelMaskPrompt}
          className="bg-blue-500 hover:bg-blue-700 text-white rounded"
        >
          Generate
        </Button>,
      ]}
      width="70%"
      className="top-50 rounded-xl shadow-xl transform transition-transform"
    >
      <Input.TextArea
        rows={4}
        value={wheelMaskPrompt}
        onChange={(e) => setWheelMaskPrompt(e.target.value)}
        placeholder="Enter your prompt here..."
        className="text-sm p-2 rounded"
      />
      <div className="prompt-suggestions flex flex-wrap justify-center mt-4">
        {currentPrompts.map((suggestion, index) => (
          <Button
            key={index}
            onClick={() => setWheelMaskPrompt(suggestion.description)}
            className="m-1 p-2 text-xs bg-sky-100 border border-transparent rounded cursor-pointer hover:bg-sky-200"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            <span className="inline-block w-full" title={suggestion.description}>
              {suggestion.description}
            </span>
          </Button>
        ))}
      </div>
      <div className="pagination-buttons flex justify-between mt-4">
        <Button
          disabled={currentPromptPage === 1}
          onClick={() => setCurrentPromptPage(currentPromptPage - 1)}
          className="bg-gray-300 hover:bg-gray-400 rounded"
        >
          Previous
        </Button>
        <Button
          disabled={endIndex >= promptSuggestions.length}
          onClick={() => setCurrentPromptPage(currentPromptPage + 1)}
          className="bg-gray-300 hover:bg-gray-400 rounded"
        >
          Next
        </Button>
      </div>
    </Modal>
  )
}

export default WheelMaskOptions
