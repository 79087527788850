import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaList, FaThLarge, FaPlus, FaFilter, FaEdit } from 'react-icons/fa'
import CreateProjectForm from '../modals/CreateProjectForm'
import { useAuth } from '../../contexts/auth-context'
import makeAuthorizedRequest from '../../core/api'
import { Spin, Modal } from 'antd'
import { useProject } from '../../contexts/project-context'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import './ProjectGallery.css'

const ProjectGallery = () => {
  const { idToken } = useAuth()
  const [projects, setProjects] = useState([])
  const [designs, setDesigns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [view, setView] = useState('mosaic')
  const [currentPage, setCurrentPage] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedDesign, setSelectedDesign] = useState(null)
  const { setCurrentProject } = useProject()
  const [inProgressProjectId, setInProgressProjectId] = useState(null)
  const itemsPerPage = 6
  const navigate = useNavigate() // Initialize navigate

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true)
      try {
        const response = await makeAuthorizedRequest('/project', 'GET', null, idToken)
        setProjects(Array.isArray(response) ? response : [])
      } catch (error) {
        setProjects([])
        console.error('Error fetching projects:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProjects()
  }, [idToken, refresh])

  const fetchDesignsWithAttachments = async (projectId, token) => {
    const response = await makeAuthorizedRequest(`/design/project?projectId=${projectId}`, 'GET', null, token)
    return response
  }

  useEffect(() => {
    if (selectedProject) {
      const fetchDesigns = async () => {
        try {
          const response = await fetchDesignsWithAttachments(selectedProject.id, idToken)
          setDesigns(response)
        } catch (error) {
          console.error('Error fetching designs:', error)
        }
      }

      fetchDesigns()
    }
  }, [selectedProject, idToken])

  const lastProjectIndex = currentPage * itemsPerPage
  const firstProjectIndex = lastProjectIndex - itemsPerPage
  const totalItems = projects.length ? Math.ceil(projects.length / itemsPerPage) : 0
  const currentProjects = projects.slice(firstProjectIndex, lastProjectIndex)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const handleCardClick = (project) => {
    setSelectedProject(project)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleBackToList = () => {
    setSelectedProject(null)
  }

  const handleEditProject = (project) => {
    setSelectedProject(project)
    setIsOpen(true)
  }

  const handleUpdateProject = (updatedProject) => {
    setSelectedProject(updatedProject)
    setProjects(projects.map((project) => (project.id === updatedProject.id ? updatedProject : project)))
    setRefresh(!refresh)
  }

  const handleStartProject = (project) => {
    if (inProgressProjectId === project.id) {
      setCurrentProject(null)
      setInProgressProjectId(null)
    } else {
      setCurrentProject(project)
      setInProgressProjectId(project.id)
      localStorage.setItem(project.id, JSON.stringify(project))
      navigate(`/edit/${project.id}`) // Navigate to the EditPage with project id
    }
  }

  const handleDesignClick = (design) => {
    setSelectedDesign(design)
  }

  const handleCloseDesignModal = () => {
    setSelectedDesign(null)
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="mt-5 px-5 lg:px-20 max-w-7xl mx-auto flex-grow">
        <div className="flex justify-between items-center mb-3">
          <h4 className="text-2xl font-bold">Projects</h4>
          {!selectedProject && (
            <div className="flex space-x-4 items-center">
              <button
                onClick={() => setView('list')}
                className={`p-2 rounded-md ${
                  view === 'list' ? 'bg-blue-700 text-white' : 'bg-blue-600 text-white hover:bg-blue-800'
                }`}
              >
                <FaList size={20} />
              </button>
              <button
                onClick={() => setView('mosaic')}
                className={`p-2 rounded-md ${
                  view === 'mosaic' ? 'bg-blue-700 text-white' : 'bg-blue-600 text-white hover:bg-blue-800'
                }`}
              >
                <FaThLarge size={20} />
              </button>
              <button
                onClick={() => setIsOpen(true)}
                className="p-2 rounded-md bg-green-600 text-white hover:bg-green-800"
              >
                <FaPlus size={20} />
              </button>
              <button className="p-2 rounded-md bg-gray-600 text-white hover:bg-gray-800">
                <FaFilter size={20} />
              </button>
            </div>
          )}
        </div>
        <CreateProjectForm
          isOpen={isOpen}
          setIsOpen={handleCloseModal}
          project={selectedProject}
          onSave={handleUpdateProject}
        />
        {isLoading ? (
          <div className="flex justify-center items-center flex-grow">
            <Spin size="large" />
          </div>
        ) : selectedProject ? (
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={selectedProject.thumbnail} alt={selectedProject.name} className="w-full h-64 object-cover" />
            <div className="p-6">
              <button onClick={handleBackToList} className="mb-4 text-blue-600 hover:underline">
                Back to List
              </button>
              <div className="flex justify-between items-center mb-4">
                <h5 className="text-2xl font-bold">{selectedProject.name}</h5>
                <button
                  onClick={() => handleEditProject(selectedProject)}
                  className="p-2 rounded-md bg-yellow-500 text-white hover:bg-yellow-600"
                >
                  <FaEdit size={20} />
                </button>
              </div>
              <p className="text-gray-700 mb-4">{selectedProject.description}</p>
              <p className="text-gray-500 mb-2">Status: {selectedProject.status}</p>
              <p className="text-gray-500 mb-2">Priority: {selectedProject.priority}</p>
              <p className="text-gray-500 mb-2">Deadline: {new Date(selectedProject.deadline).toLocaleString()}</p>
              <p className="text-gray-500 mb-4">Budget: ${selectedProject.budget}</p>
              <h6 className="text-lg font-bold mb-2">Team Members</h6>
              <ul className="list-disc ml-5 mb-4">
                {selectedProject.teamMembers.map((member) => (
                  <li key={member.userId}>{member.role}</li>
                ))}
              </ul>
              <h6 className="text-lg font-bold mb-2">Attachments</h6>
              <div className="flex flex-wrap gap-4">
                {designs.map((design, index) => (
                  <div
                    key={index}
                    className="design-thumbnail w-32 h-32 border rounded-lg overflow-hidden cursor-pointer"
                    onClick={() => handleDesignClick(design)}
                  >
                    <div className="thumbnail-stack">
                      {design.attachments.slice(0, 3).map((attachment, i) => (
                        <img
                          key={i}
                          src={attachment.downloadUrl}
                          alt={attachment.originalFileName}
                          className={`thumbnail-image ${i === 0 ? 'top' : ''} ${i === 1 ? 'middle' : ''} ${
                            i === 2 ? 'bottom' : ''
                          }`}
                        />
                      ))}
                    </div>
                    <div className="design-name-overlay">
                      <span className="text-center">{design.name}</span>
                    </div>
                  </div>
                ))}
              </div>
              {selectedProject.status === 'Active' && (
                <button
                  onClick={() => handleStartProject(selectedProject)}
                  className={`mt-4 px-4 py-2 rounded-md text-white ${
                    inProgressProjectId === selectedProject.id
                      ? 'bg-yellow-500 hover:bg-yellow-600'
                      : 'bg-blue-500 hover:bg-blue-600'
                  }`}
                >
                  {inProgressProjectId === selectedProject.id ? 'In Progress' : 'Start'}
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            {projects.length === 0 ? (
              <div className="flex-grow flex justify-center items-center">
                <p className="text-xl text-gray-500">No projects found</p>
              </div>
            ) : (
              <div
                className={`grid ${
                  view === 'list' ? 'grid-cols-1' : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'
                } gap-4`}
              >
                {currentProjects.map((project) => (
                  <div
                    key={project.id}
                    onClick={() => handleCardClick(project)}
                    className="relative transition-shadow duration-300 ease-in-out transform hover:scale-105 cursor-pointer shadow-lg hover:shadow-xl p-4 rounded-lg bg-white h-60"
                  >
                    <img src={project.thumbnail} alt={project.name} className="w-full h-40 object-cover rounded-t-md" />
                    {inProgressProjectId === project.id && (
                      <div className="absolute top-0 right-0 mt-2 mr-2 p-1 rounded bg-yellow-500 text-white font-bold text-xs shadow-md">
                        In Progress
                      </div>
                    )}
                    <div className="p-2 flex flex-col justify-between h-full">
                      <div className="flex justify-between items-center">
                        <h5 className="text-lg font-semibold mb-2">{project.name}</h5>
                        <div className="flex space-x-2">
                          <span className="bg-gray-200 text-gray-800 text-xs font-bold px-2 py-1 rounded">
                            {project.status}
                          </span>
                          <span className="bg-gray-200 text-gray-800 text-xs font-bold px-2 py-1 rounded">
                            {project.priority}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      {!selectedProject && (
        <div className="flex justify-center mt-8 mb-5">
          <div className="flex space-x-1">
            {Array.from({ length: totalItems }, (_, i) => i + 1).map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={`px-4 py-2 text-sm rounded-md ${
                  number === currentPage ? 'bg-blue-600 text-white' : 'bg-white text-gray-800 hover:bg-blue-200'
                }`}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      )}

      <Modal visible={!!selectedDesign} onCancel={handleCloseDesignModal} footer={null} width={800}>
        {selectedDesign && (
          <div>
            <h3 className="text-lg font-bold mb-4">{selectedDesign.name}</h3>
            <div className="flex flex-wrap gap-4">
              {selectedDesign.attachments.map((attachment, index) => (
                <div key={index} className="w-48 h-48 border rounded-lg overflow-hidden">
                  <a href={attachment.downloadUrl} target="_blank" rel="noopener noreferrer">
                    <img
                      src={attachment.downloadUrl}
                      alt={attachment.originalFileName}
                      className="w-full h-full object-cover"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

ProjectGallery.propTypes = {
  projects: PropTypes.array.isRequired,
}

export default ProjectGallery
