import React, { createContext, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react' // Import Auth0 components

// Create a context for authentication
const AuthContext = createContext()

// AuthProvider component to manage authentication
export const AuthProvider = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, getIdTokenClaims } = useAuth0() // Auth0 methods
  const [idToken, setIdToken] = useState(null) // State to store the id_token

  // Fetch the id_token when authentication state changes
  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        const token = await getIdTokenClaims() // Get the id_token from Auth0
        setIdToken(token.__raw) // Store the raw id_token
      }
    }

    fetchToken() // Fetch the token
  }, [isAuthenticated]) // React on authentication status change

  return <AuthContext.Provider value={{ isAuthenticated, loginWithRedirect, idToken }}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// Custom hook to use the Auth context
export const useAuth = () => {
  return useContext(AuthContext) // Access the Auth context
}
