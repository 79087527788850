import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
const ProjectContext = createContext()

export const useProject = () => {
  return useContext(ProjectContext)
}

export const ProjectProvider = ({ children }) => {
  const [currentProject, setCurrentProject] = useState(null)

  return <ProjectContext.Provider value={{ currentProject, setCurrentProject }}>{children}</ProjectContext.Provider>
}

ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
