// src/contexts/UIContext.js
import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const UIContext = createContext({
  showSidebar: true, // Default value
  setShowSidebar: () => {}, // Default empty function
})

export const UIProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true)

  console.log('showSidebar', showSidebar)

  return <UIContext.Provider value={{ showSidebar, setShowSidebar }}>{children}</UIContext.Provider>
}

UIProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useUI = () => useContext(UIContext)
