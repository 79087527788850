import { nearPoint } from '.'
import { ElementType, PointType, Tools } from '../types.ts'

export const getElementAtPosition = (x: number, y: number, elements: ElementType[]) => {
  return elements
    .map((element) => ({
      ...element,
      position: positionWithinElement(x, y, element),
    }))
    .find((element) => element.position !== null)
}

const positionWithinElement = (x: number, y: number, element: ElementType) => {
  const { type, x1, x2, y1, y2 } = element
  switch (type) {
    case Tools.line: {
      const on = onLine(x1, y1, x2, y2, x, y)
      const start = nearPoint(x, y, x1, y1, 'start')
      const end = nearPoint(x, y, x2, y2, 'end')
      return start || end || on
    }
    case Tools.rectangle: {
      const topLeft = nearPoint(x, y, x1, y1, 'topLeft')
      const topRight = nearPoint(x, y, x2, y1, 'topRight')
      const bottomLeft = nearPoint(x, y, x1, y2, 'bottomLeft')
      const bottomRight = nearPoint(x, y, x2, y2, 'bottomRight')
      const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? 'inside' : null
      return topLeft || topRight || bottomLeft || bottomRight || inside
    }
    case Tools.pencil: {
      const betweenAnyPoint = element.points!.some((point, index) => {
        const nextPoint = element.points![index + 1]
        if (!nextPoint) return false
        return onLine(point.x, point.y, nextPoint.x, nextPoint.y, x, y, 5) != null
      })
      return betweenAnyPoint ? 'inside' : null
    }
    case Tools.text:
      return x >= x1 && x <= x2 && y >= y1 && y <= y2 ? 'inside' : null
    default:
      throw new Error(`Type not recognised: ${type}`)
  }
}

const onLine = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x: number,
  y: number,
  maxDistance: number = 1,
): string | null => {
  const a: PointType = { x: x1, y: y1 }
  const b: PointType = { x: x2, y: y2 }
  const c: PointType = { x, y }
  const offset = distance(a, b) - (distance(a, c) + distance(b, c))
  return Math.abs(offset) < maxDistance ? 'inside' : null
}

const distance = (a: PointType, b: PointType) => Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2))
