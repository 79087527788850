import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import rough from 'roughjs'
import { useHistory } from './hooks/useHistory.ts'
import { usePressedKeys } from './hooks/usePressedKeys.ts'
import { Tools } from './types.ts'
import { ActionBar, ControlPanel, ResizableBox } from './components'
import {
  adjustElementCoordinates,
  adjustmentRequired,
  createElement,
  cursorForPosition,
  drawElement,
  getElementAtPosition,
  resizedCoordinates,
} from './utilities'
import './Design.css'
import { throttle } from 'lodash'
import { useUI } from '../../contexts/UIContext'
import { useFullscreen } from '../../contexts/screen-context.js'

const DesignComponent = () => {
  const initialTool = Tools.selection
  const [pencilOptions, setPencilOptions] = useState({
    size: 5,
    opacity: 1,
    color: '#FE0945',
    pressure: 1,
  })
  const { elements, setElements, undo, redo } = useHistory([])
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 })
  const [startPanMousePosition, setStartPanMousePosition] = useState({
    x: 0,
    y: 0,
  })
  const [action, setAction] = useState('none')
  const [tool, setTool] = useState(initialTool)
  const [selectedElement, setSelectedElement] = useState({})
  const [scale, setScale] = useState(1)
  const [scaleOffset, setScaleOffset] = useState({ x: 0, y: 0 })
  const textAreaRef = useRef(null)
  const pressedKeys = usePressedKeys()
  const fileInputRef = useRef(null)
  const [uploadedFile, setUploadedFile] = useState(null)
  const { setShowSidebar } = useUI()
  const { setIsFullscreen, setMargin } = useFullscreen()

  useLayoutEffect(() => {
    setShowSidebar(false)
    setIsFullscreen(true)
    setMargin('canvas')
    const canvas = document.getElementById('canvas')
    const context = canvas.getContext('2d')
    const roughCanvas = rough.canvas(canvas)

    context.clearRect(0, 0, canvas.width, canvas.height)

    const scaledWidth = canvas.width * scale
    const scaledHeight = canvas.height * scale
    const scaleOffsetX = (scaledWidth - canvas.width) / 2
    const scaleOffsetY = (scaledHeight - canvas.height) / 2
    setScaleOffset({ x: scaleOffsetX, y: scaleOffsetY })

    context.save()
    context.translate(panOffset.x * scale - scaleOffsetX, panOffset.y * scale - scaleOffsetY)
    context.scale(scale, scale)

    // Drawing the grid with 20px spacing considering scale
    drawGrid(context, -scaledWidth, -scaledHeight, 20 * scale)

    elements.forEach((element) => {
      if (action === 'writing' && selectedElement && selectedElement.id === element.id) return
      drawElement(roughCanvas, context, element)
    })
    context.restore()
  }, [elements, action, selectedElement, panOffset, scale])

  useEffect(() => {
    const undoRedoFunction = (event) => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'z') {
          if (event.shiftKey) {
            redo()
          } else {
            undo()
          }
        } else if (event.key === 'y') {
          redo()
        }
      }
    }

    document.addEventListener('keydown', undoRedoFunction)
    return () => {
      document.removeEventListener('keydown', undoRedoFunction)
    }
  }, [undo, redo])

  useEffect(() => {
    const panOrZoomFunction = (event) => {
      if (pressedKeys.has('Meta') || pressedKeys.has('Control')) {
        onZoom(event.deltaY * -0.01)
      } else {
        setPanOffset((prevState) => ({
          x: prevState.x - event.deltaX,
          y: prevState.y - event.deltaY,
        }))
      }
    }

    document.addEventListener('wheel', panOrZoomFunction)
    return () => {
      document.removeEventListener('wheel', panOrZoomFunction)
    }
  }, [pressedKeys])

  useEffect(() => {
    const textArea = textAreaRef.current
    if (action === 'writing' && textArea && selectedElement) {
      setTimeout(() => {
        textArea.focus()
        textArea.value = selectedElement.text || ''
      }, 0)
    }
  }, [action, selectedElement])

  const updateElement = (id, x1, y1, x2, y2, type, options) => {
    const elementsCopy = [...elements]
    console.log('options----------->', options)
    switch (type) {
      case Tools.line:
      case Tools.rectangle: {
        elementsCopy[id] = createElement(id, x1, y1, x2, y2, type)
        break
      }
      case Tools.pencil: {
        const existingPoints = elementsCopy[id].points || []
        elementsCopy[id].points = [...existingPoints, { x: x2, y: y2 }]
        break
      }
      case Tools.text: {
        const canvas = document.getElementById('canvas')
        if (!(canvas instanceof HTMLCanvasElement)) {
          throw new Error('Canvas element not found')
        }
        const context = canvas.getContext('2d')
        if (!context) {
          throw new Error('Could not get 2D context from canvas')
        }
        if (!options) {
          throw new Error('No text options provided for text tool')
        }

        const textWidth = context.measureText(options.text).width
        const textHeight = 24
        elementsCopy[id] = {
          ...createElement(id, x1, y1, x1 + textWidth, y1 + textHeight, type),
          text: options.text,
        }
        break
      }
      default:
        throw new Error(`Type not recognised: ${type}`)
    }
    setElements(elementsCopy, true)
  }

  const clearCanvas = () => {
    setElements([])
  }

  const drawGrid = (context, width, height, spacing = 20) => {
    context.beginPath()
    context.strokeStyle = '#eee' // Light grey color for the grid lines
    context.lineWidth = 1

    // Drawing vertical lines
    for (let x = spacing; x < width; x += spacing) {
      context.moveTo(x, 0)
      context.lineTo(x, height)
    }
    // Drawing horizontal lines
    for (let y = spacing; y < height; y += spacing) {
      context.moveTo(0, y)
      context.lineTo(width, y)
    }
    context.stroke()
  }

  const getEventCoordinates = (event) => {
    if (event.touches && event.touches.length > 0) {
      const touch = event.touches[0]
      console.log('touches -->', touch)
      if (touch.force !== undefined) {
        setPencilOptions((prevOptions) => ({
          ...prevOptions,
          pressure: touch.force, // Update pressure dynamically
        }))
      }
      return {
        clientX: (touch.clientX - panOffset.x * scale + scaleOffset.x) / scale,
        clientY: (touch.clientY - panOffset.y * scale + scaleOffset.y) / scale,
      }
    } else if (event.clientX && event.clientY) {
      return {
        clientX: (event.clientX - panOffset.x * scale + scaleOffset.x) / scale,
        clientY: (event.clientY - panOffset.y * scale + scaleOffset.y) / scale,
      }
    } else {
      console.error('Unsupported event type or missing coordinates')
      return { clientX: 0, clientY: 0 }
    }
  }

  const handleMouseDown = (event) => {
    if (action === 'writing') return

    console.log('action-->', action)

    const { clientX, clientY } = getEventCoordinates(event)

    if (tool === Tools.pan || event.button === 1 || pressedKeys.has(' ')) {
      setAction('panning')
      setStartPanMousePosition({ x: clientX, y: clientY })
      document.body.style.cursor = 'grabbing'
      return
    }

    if (event.button === 1 || pressedKeys.has(' ')) {
      setAction('panning')
      setStartPanMousePosition({ x: clientX, y: clientY })
      document.body.style.cursor = 'grabbing'
      return
    }

    if (tool === Tools.selection) {
      const element = getElementAtPosition(clientX, clientY, elements)

      if (element) {
        let selectedElement = { ...element }

        if (element.type === 'pencil' && element.points) {
          const xOffsets = element.points.map((point) => clientX - point.x)
          const yOffsets = element.points.map((point) => clientY - point.y)
          selectedElement = { ...selectedElement, xOffsets, yOffsets }
        } else {
          const offsetX = clientX - selectedElement.x1
          const offsetY = clientY - selectedElement.y1
          selectedElement = { ...selectedElement, offsetX, offsetY }
        }

        setSelectedElement(selectedElement)
        setElements((prevState) => prevState)

        if (element.position === 'inside') {
          setAction('moving')
        } else {
          setAction('resizing')
        }
      }
    } else {
      const id = elements.length
      const newElement = createElement(id, clientX, clientY, clientX, clientY, tool, pencilOptions)
      setElements((prevState) => [...prevState, newElement])
      setSelectedElement(newElement)
      setAction(tool === 'text' ? 'writing' : tool === 'upload' ? 'upload' : 'drawing')
    }
  }

  const handleMouseMove = (event) => {
    if (action !== 'drawing' && action !== 'moving' && action !== 'resizing') return
    const { clientX, clientY } = getEventCoordinates(event)

    if (action === 'panning') {
      const deltaX = clientX - startPanMousePosition.x
      const deltaY = clientY - startPanMousePosition.y
      setPanOffset({
        x: panOffset.x + deltaX,
        y: panOffset.y + deltaY,
      })
      return
    }

    if (tool === Tools.selection) {
      const element = getElementAtPosition(clientX, clientY, elements)

      if (element && element.position) {
        event.target.style.cursor = cursorForPosition(element.position)
      } else {
        event.target.style.cursor = 'default'
      }
    }

    if (action === 'drawing') {
      const index = elements.length - 1
      const { x1, y1 } = elements[index]
      updateElement(index, x1, y1, clientX, clientY, tool, pencilOptions)
    } else if (action === 'moving' && selectedElement) {
      if (
        selectedElement.type === 'pencil' &&
        'points' in selectedElement &&
        'xOffsets' in selectedElement &&
        'yOffsets' in selectedElement
      ) {
        const extendedElement = selectedElement
        const newPoints = extendedElement.points.map((_, index) => ({
          x: clientX - extendedElement.xOffsets[index],
          y: clientY - extendedElement.yOffsets[index],
        }))
        const elementsCopy = [...elements]
        elementsCopy[extendedElement.id] = {
          ...elementsCopy[extendedElement.id],
          points: newPoints,
        }
        setElements(elementsCopy, true)
      } else {
        const { id, x1, x2, y1, y2, type, offsetX, offsetY } = selectedElement
        const safeOffsetX = offsetX ?? 0
        const safeOffsetY = offsetY ?? 0
        const newX1 = clientX - safeOffsetX
        const newY1 = clientY - safeOffsetY
        // 🫐 Calculate the new position for x2 and y2 based on the original size
        const newX2 = newX1 + (x2 - x1)
        const newY2 = newY1 + (y2 - y1)
        const options = type === 'text' && selectedElement.text ? { text: selectedElement.text } : undefined
        updateElement(id, newX1, newY1, newX2, newY2, type, options)
      }
    } else if (action === 'resizing' && selectedElement && selectedElement.position) {
      const { id, type, position, ...coordinates } = selectedElement

      if (typeof position === 'string') {
        const { x1, y1, x2, y2 } = resizedCoordinates(clientX, clientY, position, coordinates)
        updateElement(id, x1, y1, x2, y2, type)
      }
    }
  }

  const handleMouseUp = (event) => {
    if (!event) {
      console.error('Event is undefined in handleMouseUp')
      return
    }

    const { clientX, clientY } = getEventCoordinates(event)

    if (selectedElement && elements[selectedElement.id] !== undefined) {
      const index = selectedElement.id
      const element = elements[index]

      if (element) {
        const { id, type } = element
        if ((action === 'drawing' || action === 'resizing') && adjustmentRequired(type)) {
          const { x1, y1, x2, y2 } = adjustElementCoordinates(element)
          updateElement(id, x1, y1, x2, y2, type)
        }

        const offsetX = selectedElement.offsetX || 0
        const offsetY = selectedElement.offsetY || 0

        if (
          selectedElement.type === 'text' &&
          clientX - offsetX === selectedElement.x1 &&
          clientY - offsetY === selectedElement.y1
        ) {
          setAction('writing')
          return
        }
      }
    }

    if (action === 'writing') {
      return
    }

    if (action === 'panning') {
      document.body.style.cursor = 'default'
    }

    setAction('none')
    setSelectedElement(null)
  }

  const handleBlur = (event) => {
    if (selectedElement) {
      const { id, x1, y1, type } = selectedElement

      const x2 = selectedElement.x2 || x1
      const y2 = selectedElement.y2 || y1

      setAction('none')
      setSelectedElement(null)
      updateElement(id, x1, y1, x2, y2, type, { text: event.target.value })
    } else {
      console.error('No element selected when handleBlur was called')
    }
  }

  const onZoom = (delta) => {
    setScale((prevState) => Math.min(Math.max(prevState + delta, 0.1), 20))
  }

  const fitToScreen = () => {
    const canvas = document.getElementById('canvas')
    if (canvas) {
      const { width, height } = canvas
      const scaleX = window.innerWidth / width
      const scaleY = window.innerHeight / height
      const newScale = Math.min(scaleX, scaleY) * 0.9 // Multiplied by 0.9 to add some margin

      setScale(newScale)
      // Optionally reset pan offsets here
      setPanOffset({ x: 0, y: 0 })
    }
  }

  const openFileExplorer = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setUploadedFile(event.target.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDrop = (e) => {
    e.preventPreventDefaultault()
    console.log(e.dataTransfer.files)
  }

  // Adding touch equivalents of mouse event handlers
  const handleTouchStart = (event) => {
    handleMouseDown(event) // Call the same function used for mouse down
    event.preventDefault() // Prevent the default touch behavior like scrolling
  }

  const throttledHandleTouchMove = throttle((event) => {
    handleMouseMove(event)
  }, 10) // Adjust throttle time as needed

  const handleTouchMove = (event) => {
    if (action !== 'drawing' && action !== 'moving' && action !== 'resizing') return
    throttledHandleTouchMove(event)
    event.preventDefault()
  }

  const handlePointerUp = (event) => {
    handleMouseUp(event)
    event.preventDefault() // Prevent default behavior for pointer events
  }

  const handleTouchEnd = (event) => {
    handleMouseUp(event)
    event.preventDefault() // Prevent default behavior for touch events
  }

  const handleTouchCancel = (event) => {
    handleMouseUp(event)
    event.preventDefault() // Prevent default behavior for touch cancellation
  }

  const handlePointerDown = (event) => {
    handleMouseDown(event)
    event.preventDefault()
  }

  const handlePointerMove = (event) => {
    handleMouseMove(event)
    event.preventDefault()
  }

  return (
    <div className="design">
      <ActionBar tool={tool} setTool={setTool} pencilOptions={pencilOptions} setPencilOptions={setPencilOptions} />
      <ControlPanel
        undo={undo}
        redo={redo}
        onZoom={onZoom}
        scale={scale}
        setScale={setScale}
        clearCanvas={clearCanvas}
        fitToScreen={fitToScreen}
      />
      {action === 'writing' ? (
        <textarea
          ref={textAreaRef}
          onBlur={handleBlur}
          className="textArea"
          style={{
            top: selectedElement ? (selectedElement.y1 - 2) * scale + panOffset.y * scale - scaleOffset.y : 0,
            left: selectedElement ? selectedElement.x1 * scale + panOffset.x * scale - scaleOffset.x : 0,
            font: `${24 * scale}px sans-serif`,
          }}
        />
      ) : null}
      <canvas
        id="canvas"
        width={window.innerWidth}
        height={window.innerHeight}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        style={{ position: 'absolute', zIndex: 1, touchAction: 'none' }}
      />
      {tool === Tools.upload && (
        <ResizableBox
          openFileExplorer={openFileExplorer}
          handleDrop={handleDrop}
          handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          Tools={Tools}
          tool={Tools.upload}
          uploadedFile={uploadedFile}
        />
      )}
    </div>
  )
}

export default DesignComponent
