export const styles = [
  {
    value: 'surface',
    title: 'Surfacing',
    description: 'Creative surface designs and styling',
    image: './options/surface.png',
  },
  {
    value: 'concept',
    title: 'Concept Designs',
    description: 'Create science fiction futuristic designs',
    image: './options/concept.png',
  },
  {
    value: 'modern',
    title: 'Modern Styling',
    description: 'Modern car styling',
    image: './options/modern.png',
  },
  {
    value: 'interior',
    title: 'Interior Design',
    description: 'Create new styling for your car interiors',
    image: './options/interior.png',
  },
  {
    value: 'generic',
    title: 'Generic Design',
    description: 'Random designs',
    image: './options/generic.png',
  },
  {
    value: 'classic_wheels',
    title: 'Classic Wheels',
    description: 'Classic Wheels designs',
    image: './options/wheels.png',
  },
]
