import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Layout } from './common/layout'
import { NotFoundPage } from './common/notFoundPage'
import { HomePage } from './pages/homePage'
import { EditPage } from './pages/editPage'
import ProjectGallery from './pages/projects/ProjectGallery'
import projects from './data/projects'
import DesignComponent from './pages/canvas/Design'
import { useUI } from './contexts/UIContext'
import { useFullscreen } from './contexts/screen-context'
import Loading from './common/Loading'

function App() {
  const { showSidebar, setShowSidebar } = useUI()
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const { isFullscreen, setIsFullscreen } = useFullscreen()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && isFullscreen) {
        setIsFullscreen(false)
        setShowSidebar(true)
      }
    }

    // Add the event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, isFullscreen, setIsFullscreen, setShowSidebar])

  const mainStyle = {
    marginLeft: showSidebar ? '17%' : '0', // Ensure this matches the expected state variable
    marginRight: '2%',
    transition: 'margin-left 300ms ease',
  }

  return (
    <HashRouter>
      <div style={mainStyle}>
        <Layout>
          <Routes>
            <Route path="/" element={isAuthenticated ? <HomePage /> : <Loading />} />
            <Route path="/edit/:projectId" element={isAuthenticated ? <EditPage /> : <Loading />} />
            <Route path="/canvas" element={isAuthenticated ? <DesignComponent /> : <Loading />} />
            <Route path="/projects" element={isAuthenticated ? <ProjectGallery projects={projects} /> : <Loading />} />
            <Route path="*" element={isAuthenticated ? <NotFoundPage /> : <Loading />} />
          </Routes>
        </Layout>
      </div>
    </HashRouter>
  )
}

export default App
