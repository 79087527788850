import { Tooltip } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faDownload,
  faExchangeAlt,
  faCheck,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
/* eslint-disable react/prop-types */
const ImagePreview = ({ images, currentIndex, originalSrc, onConfirm, onCancel, onDownload, setCurrentIndex }) => {
  const [showOriginal, setShowOriginal] = useState(false)
  const [scale, setScale] = useState(1)
  const imageRef = useRef(null)

  const currentImage = images[currentIndex]
  const image = originalSrc.toDataURL({ format: 'image/jpeg' })

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleWheel = (e) => {
    e.preventDefault()
    const scaleAmount = e.deltaY * -0.01
    const newScale = Math.min(Math.max(scale + scaleAmount, 1), 3) // Minimum scale is 1

    // Get the bounding rectangle of the image
    const rect = imageRef.current.getBoundingClientRect()

    // Calculate the mouse position relative to the image
    const offsetX = (e.clientX - rect.left) / rect.width
    const offsetY = (e.clientY - rect.top) / rect.height

    // Calculate the new transform origin
    const originX = offsetX * 100
    const originY = offsetY * 100

    // Apply the new scale and transform origin with smooth transition
    imageRef.current.style.transition = 'transform 0.1s ease-out'
    imageRef.current.style.transformOrigin = `${originX}% ${originY}%`
    imageRef.current.style.transform = `scale(${newScale})`

    // Update the scale state
    setScale(newScale)
  }

  useEffect(() => {
    const img = imageRef.current
    img.addEventListener('wheel', handleWheel, { passive: false })

    return () => {
      img.removeEventListener('wheel', handleWheel)
    }
  }, [scale])

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center p-4 z-50">
      <div className="relative bg-white rounded-xl overflow-hidden shadow-xl transform transition-all">
        <div className="absolute top-0 right-0 flex flex-col p-2 space-y-2 z-10">
          <Tooltip title="Close" placement="left" arrow>
            <button onClick={onCancel} className="p-2 hover:bg-gray-700 hover:border-2 rounded-full border-white-800">
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </Tooltip>
          <Tooltip title="Download" placement="left" arrow>
            <button onClick={onDownload} className="p-2 hover:bg-gray-700 hover:border-2 rounded-full border-white-800">
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </Tooltip>
          <Tooltip title="Previous" placement="left" arrow>
            <button
              onClick={handlePrevious}
              disabled={currentIndex === 0}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            </button>
          </Tooltip>
          <Tooltip title="Next" placement="left" arrow>
            <button
              onClick={handleNext}
              disabled={currentIndex === images.length - 1}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </button>
          </Tooltip>
          <Tooltip title={showOriginal ? 'Show Generated' : 'Compare'} placement="left" arrow>
            <button
              onClick={() => setShowOriginal(!showOriginal)}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />
            </button>
          </Tooltip>
          <Tooltip title="Add to canvas" placement="left" arrow>
            <button
              onClick={() => onConfirm(currentImage)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <FontAwesomeIcon icon={faCheck} className="mr-2" />
            </button>
          </Tooltip>
        </div>
        <div className="relative">
          <img
            ref={imageRef}
            src={showOriginal ? image : currentImage.src}
            alt="Preview"
            className="w-full h-auto max-h-[80vh] max-w-[80vw] object-cover rounded-xl"
            onWheel={handleWheel}
          />
        </div>
      </div>
    </div>
  )
}

export default ImagePreview
