import React from 'react'
import { Modal, Form, Input, Button } from 'antd'
import PropTypes from 'prop-types'
import { FaUserEdit } from 'react-icons/fa'
import './ProfileEditForm.css'

var ProfileEditForm = ({ userData, showModal, setShowModal }) => {
  const handleClose = () => setShowModal(false)

  const { user } = userData

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Profile saved!', event)
    setShowModal(false) // Close modal on save
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <Modal
        title={
          <span>
            <FaUserEdit className="inline mr-2 text-lg" /> Edit Profile
          </span>
        }
        open={showModal}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleSubmit()}>
            Save Changes
          </Button>,
        ]}
        centered
      >
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Username" className="mb-3">
            <Input defaultValue={user.username} disabled readOnly />
          </Form.Item>
          <Form.Item label="Role" className="mb-3">
            <Input defaultValue={user.role} disabled readOnly />
          </Form.Item>
          <Form.Item label="Name" className="mb-3">
            <Input defaultValue={user.name} onChange={(e) => console.log(e.target.value)} />
          </Form.Item>
          <Form.Item label="Email" className="mb-3">
            <Input type="email" defaultValue={user.email} onChange={(e) => console.log(e.target.value)} />
          </Form.Item>
          <Form.Item label="Phone Number" className="mb-3">
            <Input type="tel" defaultValue={user.phoneNumber} onChange={(e) => console.log(e.target.value)} />
          </Form.Item>
          <Form.Item label="Address" className="mb-3">
            <Input defaultValue={user.address} onChange={(e) => console.log(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

ProfileEditForm.propTypes = {
  userData: PropTypes.object.isRequired,
  showModal: PropTypes.node.isRequired,
  setShowModal: PropTypes.func.isRequired,
}

export default ProfileEditForm
