import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import { GlobalStyles } from 'twin.macro'
import { Global } from '@emotion/react'
import { baseStyles } from './styles'
import { Header, Footer } from '.'
import { useFullscreen } from '../../contexts/screen-context'

const Layout = ({ children }) => {
  const { isFullscreen, margin } = useFullscreen()
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    }
  }, [isAuthenticated, isLoading, loginWithRedirect])

  const mainStyle = {
    height: isFullscreen ? '100vh' : 'auto',
    width: isFullscreen ? '100vw' : 'auto',
    overflow: isFullscreen ? 'auto' : 'visible',
  }

  return (
    <>
      <GlobalStyles />
      <Global styles={baseStyles} />
      {isAuthenticated && !isFullscreen && <Header />}
      <main
        id="main"
        className={`relative ${isFullscreen ? 'fixed inset-0 z-50' : 'max-w-full'}`}
        tabIndex={-1}
        style={mainStyle}
      >
        {children}
      </main>
      {isAuthenticated && !isFullscreen && margin !== 'canvas' && <Footer />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
