const projects = [
  {
    id: '1',
    name: 'Project Alpha',
    description:
      'This is a detailed description of Project Alpha, focusing on its core objectives and expected outcomes.',
    thumbnail: 'https://via.placeholder.com/300x200.png?text=Project+Alpha',
    status: 'Active',
    priority: 'High',
    deadline: '2023-12-31',
    createdAt: '2023-01-01',
    updatedAt: '2023-04-01',
    teamMembers: JSON.stringify([
      { name: 'John Doe', role: 'Developer' },
      { name: 'Jane Smith', role: 'Designer' },
    ]),
    budget: 120000.0,
    createdBy: 'user1234',
  },
  {
    id: '2',
    name: 'Project Beta',
    description:
      'Project Beta aims to innovate in the field of biotechnology with a focus on sustainability and global impact.',
    thumbnail: 'https://via.placeholder.com/300x200.png?text=Project+Beta',
    status: 'Pending',
    priority: 'Medium',
    deadline: '2024-06-30',
    createdAt: '2023-02-15',
    updatedAt: '2023-04-15',
    teamMembers: JSON.stringify([{ name: 'Alice Johnson', role: 'Project Manager' }]),
    budget: 500000.0,
    createdBy: 'user5678',
  },
  {
    id: '3',
    name: 'Project Gamma',
    description: 'An exploration into advanced AI technologies to build an autonomous logistics network.',
    thumbnail: 'https://via.placeholder.com/300x200.png?text=Project+Gamma',
    status: 'Completed',
    priority: 'Low',
    deadline: '2023-10-10',
    createdAt: '2023-03-10',
    updatedAt: '2023-05-10',
    teamMembers: JSON.stringify([{ name: 'Michael Corleone', role: 'Analyst' }]),
    budget: 75000.0,
    createdBy: 'user9876',
  },
  {
    id: '4',
    name: 'Project Delta',
    description: 'Developing new frameworks for digital education and online learning platforms.',
    thumbnail: 'https://via.placeholder.com/300x200.png?text=Project+Delta',
    status: 'On Hold',
    priority: 'High',
    deadline: '2025-01-01',
    createdAt: '2023-01-20',
    updatedAt: '2023-05-20',
    teamMembers: JSON.stringify([
      { name: 'Sarah Connor', role: 'Developer' },
      { name: 'Kyle Reese', role: 'Designer' },
    ]),
    budget: 200000.0,
    createdBy: 'user4321',
  },
]

export default projects
