import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import './control-panel-style.css'
import { PiMinus, PiPlus } from 'react-icons/pi'
import { FaEraser, FaVectorSquare } from 'react-icons/fa'
import { HiOutlineArrowUturnLeft, HiOutlineArrowUturnRight } from 'react-icons/hi2'

ControlPanel.propTypes = {
  undo: PropTypes.node.isRequired,
  redo: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
  scale: PropTypes.func.isRequired,
  setScale: PropTypes.func.isRequired,
  clearCanvas: PropTypes.func.isRequired,
  fitToScreen: PropTypes.func.isRequired,
}

export function ControlPanel({ undo, redo, onZoom, scale, setScale, clearCanvas, fitToScreen }) {
  return (
    <>
      <div className="controlPanel">
        <div className="zoomPanel">
          <Tippy content="Clear Canvas">
            <button onClick={fitToScreen} aria-label="Clear Canvas">
              <FaVectorSquare /> {/* Change this icon to a more suitable one if needed */}
            </button>
          </Tippy>
          <Tippy content="Zoom Out">
            <button onClick={() => onZoom(-0.1)} aria-label="Zoom Out">
              <PiMinus />
            </button>
          </Tippy>
          <Tippy content={`Set scale to 100%`}>
            <button onClick={() => setScale(1)} aria-label={`Set scale to 100%`}>
              {new Intl.NumberFormat('en-GB', { style: 'percent' }).format(scale)}
            </button>
          </Tippy>
          <Tippy content="Zoom In">
            <button onClick={() => onZoom(0.1)} aria-label="Zoom In">
              <PiPlus />
            </button>
          </Tippy>
        </div>

        <div className="editPanel">
          <Tippy content="Clear Canvas">
            <button onClick={clearCanvas} aria-label="Clear Canvas">
              <FaEraser /> {/* Change this icon to a more suitable one if needed */}
            </button>
          </Tippy>
          <Tippy content="Undo last action">
            <button onClick={undo} aria-label="Undo last action">
              <HiOutlineArrowUturnLeft />
            </button>
          </Tippy>
          <Tippy content="Redo last action">
            <button onClick={redo} aria-label="Redo last action">
              <HiOutlineArrowUturnRight />
            </button>
          </Tippy>
        </div>
      </div>{' '}
    </>
  )
}
