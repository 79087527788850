import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './ResizableBox.css'
// eslint-disable-next-line
ResizableBox.propTypes = {
  openFileExplorer: PropTypes.node.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  fileInputRef: PropTypes.func.isRequired,
  Tools: PropTypes.func.isRequired,
  tool: PropTypes.func.isRequired,
  uploadedFile: PropTypes.func.isRequired,
}

export function ResizableBox({
  openFileExplorer,
  handleDrop,
  handleFileChange,
  fileInputRef,
  Tools,
  tool,
  uploadedFile,
}) {
  const boxRef = useRef()
  const [isResizing, setIsResizing] = useState(false)
  const [isRotating, setIsRotating] = useState(false)
  const [boxSize, setBoxSize] = useState({ width: 800, height: 600 })
  const [boxRotation, setBoxRotation] = useState(0)
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })
  const [startSize, setStartSize] = useState({ width: 800, height: 600 })
  const [startRotation, setStartRotation] = useState(0)
  const [corner, setCorner] = useState(null)

  const handleStartInteraction = (corner, e) => {
    const clientX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX
    const clientY = e.type.includes('mouse') ? e.clientY : e.touches[0].clientY

    if (corner) {
      setIsResizing(true)
      setCorner(corner)
    } else {
      setIsRotating(true)
    }

    setStartPosition({ x: clientX, y: clientY })
    setStartSize({ width: boxSize.width, height: boxSize.height })
    setStartRotation(boxRotation)
    e.preventDefault()
  }

  const handleMoveInteraction = (e) => {
    const clientX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX
    const clientY = e.type.includes('mouse') ? e.clientY : e.touches[0].clientY

    if (isResizing && corner) {
      const newWidth = Math.max(
        100,
        startSize.width + (clientX - startPosition.x) * (corner === 'bottom-right' ? 1 : -1),
      )
      const newHeight = Math.max(
        100,
        startSize.height + (clientY - startPosition.y) * (corner === 'bottom-right' ? 1 : -1),
      )
      setBoxSize({ width: newWidth, height: newHeight })
    } else if (isRotating) {
      const deltaX = clientX - startPosition.x
      const deltaY = clientY - startPosition.y
      const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI)
      setBoxRotation(startRotation + angle)
    }

    e.preventDefault()
  }

  const handleEndInteraction = () => {
    setIsResizing(false)
    setIsRotating(false)
    setCorner(null)
  }

  useEffect(() => {
    const moveEvent = isResizing || isRotating ? 'mousemove' : 'touchmove'
    const endEvent = isResizing || isRotating ? 'mouseup' : 'touchend'

    window.addEventListener(moveEvent, handleMoveInteraction)
    window.addEventListener(endEvent, handleEndInteraction)

    return () => {
      window.removeEventListener(moveEvent, handleMoveInteraction)
      window.removeEventListener(endEvent, handleEndInteraction)
    }
  }, [isResizing, isRotating])

  return (
    <>
      {tool === Tools.upload && (
        <div className="resizable">
          <div
            ref={boxRef}
            className="uploadArea"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={openFileExplorer}
            style={{
              width: boxSize.width,
              height: boxSize.height,
              transform: `translate(-50%, -50%) rotate(${boxRotation}deg)`,
              backgroundImage: uploadedFile ? `url(${uploadedFile})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            {!uploadedFile && 'Drop files here or click to upload'}

            {/* Top Left Resizer */}
            <div
              className="resizer"
              onMouseDown={(e) => handleStartInteraction('top-left', e)}
              onTouchStart={(e) => handleStartInteraction('top-left', e)}
              style={{ top: 0, left: 0, cursor: 'nwse-resize' }}
            />

            {/* Bottom Right Resizer */}
            <div
              className="resizer"
              onMouseDown={(e) => handleStartInteraction('bottom-right', e)}
              onTouchStart={(e) => handleStartInteraction('bottom-right', e)}
              style={{ bottom: 0, right: 0, cursor: 'nwse-resize' }}
            />

            <div
              className="rotator"
              onMouseDown={(e) => handleStartInteraction(null, e)}
              onTouchStart={(e) => handleStartInteraction(null, e)}
              style={{ cursor: 'crosshair' }}
            />
          </div>
        </div>
      )}
      <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} single />
    </>
  )
}
