import React from 'react'
import { StyledFooter } from './styles'

const currentYear = new Date().getFullYear()

const Footer = () => {
  return (
    <StyledFooter>
      <p className="title">Image Inpainting</p>
      <p className="author">© {currentYear} MS-RT All Rights Reserved</p>
    </StyledFooter>
  )
}

export default Footer
