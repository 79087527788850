// Base URL for the API endpoint
const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}/Prod`

// Function to upload the file to a signed URL
export const uploadFileToSignedUrlRequest = async (signedUrl, method = 'PUT', file) => {
  try {
    const headers = new Headers()
    headers.append('Content-Type', file.fileType) // Correct Content-Type based on file type

    const options = {
      method,
      headers,
      body: file, // Pass the actual file data as the request body
    }

    await fetch(signedUrl, options) // PUT request to upload the file
    console.log('File uploaded successfully.')
  } catch (error) {
    console.error('Error uploading file:', error)
  }
}

// Function to create authorized requests
export const makeAuthorizedRequest = async (endpoint, method = 'GET', data = null, token) => {
  if (!token) {
    console.error('Authorization token is missing')
    return
  }

  const headers = new Headers()
  headers.append('token', `Bearer ${token}`)
  headers.append('Content-Type', 'application/json')

  const options = {
    method,
    headers,
    body: data ? JSON.stringify(data) : null,
  }

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, options)
    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`)
    }
    return await response.json() // Parse JSON response
  } catch (error) {
    console.error('Error in authorized API request:', error)
    throw error // Rethrow the error to handle it in the caller function
  }
}

// Function to get a signed URL from the API
export const getSignedUrl = async (fileData, token) => {
  return await makeAuthorizedRequest('/upload/getsignedurl', 'POST', fileData, token)
}

// Exported function to upload the file to the signed URL
export const uploadFileToSignedUrl = async (signedUrl, file) => {
  return await uploadFileToSignedUrlRequest(signedUrl, 'PUT', file) // Correctly pass the file
}

export const uploadToSignedUrl = async (signedUrl, file) => {
  try {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    })

    if (!response.ok) {
      throw new Error(`Upload failed with status: ${response.status}`)
    }
    return response
  } catch (error) {
    console.error('Error uploading to signed URL:', error)
    throw error
  }
}

export default makeAuthorizedRequest
