import React from 'react'
import App from './App'
import ReactDOM from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { AuthProvider } from './contexts/auth-context'
import { UIProvider } from './contexts/UIContext'
import { FullscreenProvider } from './contexts/screen-context'
import { ProjectProvider } from './contexts/project-context'
import './index.css'
import 'antd/dist/antd.min.css'

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{ redirect_uri: window.location.origin }}
  >
    <AuthProvider>
      <UIProvider>
        <FullscreenProvider>
          <ProjectProvider>
            <App />
          </ProjectProvider>
        </FullscreenProvider>
      </UIProvider>
    </AuthProvider>
  </Auth0Provider>,
)
