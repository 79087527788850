/* eslint-disable react/prop-types */
import React, { useEffect, useRef, memo } from 'react'
import { Tooltip, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ToolButton = memo(
  ({
    isActive,
    onClick = null,
    onMouseDown = null,
    onMouseUp = null,
    icon,
    title = '',
    disabledPopConfirm = true,
    disabled = false,
  }) => {
    const buttonRef = useRef(null)
    useEffect(() => {
      buttonRef.current.disabled = disabled
    }, [disabled])
    return (
      <div>
        <Tooltip title={title} placement="bottom">
          <Popconfirm
            title="Are you sure to continue?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              onClick()
            }}
            disabled={disabledPopConfirm}
            overlayInnerStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adding opacity to the background
              borderRadius: '2rem', // Corresponds to Tailwind's rounded-xl (12px)
              border: '2px', // Removing default border
            }}
          >
            <button
              ref={buttonRef}
              className={`${disabled ? `hover:bg-transparent` : `hover:bg-gray-600/[0.9]`} px-1 py-1 rounded-lg ${
                isActive ? `bg-sky-200` : `bg-transparent`
              }`}
              onClick={() => {
                if (!disabledPopConfirm || onClick === null) return
                onClick()
              }}
              onMouseDown={() => {
                if (!disabledPopConfirm || onMouseDown === null) return
                onMouseDown()
              }}
              onMouseUp={() => {
                if (!disabledPopConfirm || onMouseUp === null) return
                onMouseUp()
              }}
            >
              <FontAwesomeIcon
                className={`${
                  disabled ? `text-black-700` : `transition duration-150 ease-in-out transform hover:scale-130`
                }`}
                icon={icon}
              />
            </button>
          </Popconfirm>
        </Tooltip>
      </div>
    )
  },
)

export default ToolButton
