import React, { useCallback } from 'react'
import { Slider, Switch, Tooltip } from 'antd'
import './style.css'
/* eslint-disable react/prop-types */
const RenderTab = ({
  promptText,
  setPromptText,
  drawingInfluence,
  setDrawingInfluence,
  drawingInfluenceMax,
  drawingInfluenceMin,
  denoise,
  setDenoise,
  numberOfImages,
  setNumberOfImages,
  creativityMax,
  creativityMin,
  setChangeSelectedAreaOnly,
  changeSelectedAreaOnly,
  setChangeBackgroundOnly,
  changeBackgroundOnly,
}) => {
  const handleTextChange = useCallback(
    (event) => {
      setPromptText(event.target.value)
      console.log('Prompt Updated:', event.target.value)
    },
    [setPromptText],
  )

  const handleSliderChange = useCallback(
    (v) => {
      const reversedValue = drawingInfluenceMax + drawingInfluenceMin - v
      setDrawingInfluence(reversedValue)
    },
    [drawingInfluenceMax, drawingInfluenceMin, setDrawingInfluence],
  )

  return (
    <div>
      <label className="block text-sm font-medium mb-1">Prompt:</label>
      <div className="relative mb-4">
        <textarea
          className="textarea-custom-scroll w-full h-24 p-3 text-sm rounded-md border border-gray-300 shadow-sm resize-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition duration-300 ease-in-out"
          value={promptText}
          onChange={handleTextChange}
          placeholder="Enter your prompt here..."
          title="Enter your prompt"
        />

        <div className="absolute bottom-0 right-0 flex space-x-2 p-2">
          <Tooltip title="Change selected area only">
            <div className="flex items-center">
              <Switch
                checked={changeSelectedAreaOnly}
                onChange={setChangeSelectedAreaOnly}
                size="small"
                className="mr-1"
              />
              <span className="text-xs">CSAO</span>
            </div>
          </Tooltip>
          <Tooltip title="Change background only">
            <div className="flex items-center">
              <Switch
                checked={changeBackgroundOnly}
                onChange={useCallback(() => {
                  if (!changeBackgroundOnly) {
                    setDenoise(88 / 100)
                  }
                  setChangeBackgroundOnly(!changeBackgroundOnly)
                  setDrawingInfluence(10)
                }, [changeBackgroundOnly, setDenoise, setChangeBackgroundOnly])}
                size="small"
                className="mr-1"
              />

              <span className="text-xs">CBO</span>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="w-1/2 pr-2">
          <label className="block text-sm font-medium">Drawing Influence:</label>
          <Slider
            tooltip={{ placement: 'top' }}
            defaultValue={drawingInfluenceMax}
            max={drawingInfluenceMax}
            min={drawingInfluenceMin}
            value={drawingInfluenceMax + drawingInfluenceMin - drawingInfluence}
            onChange={handleSliderChange}
            marks={{
              [drawingInfluenceMin]: {
                style: { fontSize: '10px' },
                label: '0%',
              },
              [drawingInfluenceMax]: {
                style: { fontSize: '10px' },
                label: '100%',
              },
            }}
          />
        </div>
        <div className="w-1/2 pl-2">
          <label className="block text-sm font-medium">Creativity:</label>
          <Slider
            tooltip={{ placement: 'top' }}
            defaultValue={60}
            max={creativityMax}
            min={creativityMin}
            value={denoise * 100}
            onChange={useCallback(
              (v) => {
                setDenoise(v / 100)
              },
              [setDenoise],
            )}
            marks={{
              [creativityMin]: {
                style: { fontSize: '10px' },
                label: '0%',
              },
              [creativityMax]: {
                style: { fontSize: '10px' },
                label: '100%',
              },
            }}
          />
        </div>
      </div>
      <label className="block text-sm font-medium mb-1">Number of Images:</label>
      <div className="flex space-x-2 mb-4">
        {[1, 2, 4, 8, 10, 12].map((num) => (
          <button
            key={num}
            className={`w-8 h-8 flex border-2 border-dashed items-center justify-center rounded-md border ${
              num === numberOfImages ? 'bg-gray-400' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setNumberOfImages(num)}
          >
            {num}
          </button>
        ))}
      </div>
      <div className="border-b border-white mb-4"></div>
    </div>
  )
}

export default RenderTab
