// src/contexts/UIContext.js
import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const Fullscreen = createContext({
  isFullscreen: false, // Default value
  setIsFullscreen: () => {}, // Default empty function
  margin: '0',
  setMargin: () => {},
})

export const FullscreenProvider = ({ children }) => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [margin, setMargin] = useState('0')

  return (
    <Fullscreen.Provider value={{ isFullscreen, setIsFullscreen, margin, setMargin }}>{children}</Fullscreen.Provider>
  )
}

FullscreenProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useFullscreen = () => useContext(Fullscreen)
