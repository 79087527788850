import React, { useRef, useState } from 'react'
import { Tools } from '../../types.ts'
import PropTypes from 'prop-types'
import { LuBrush, LuUpload } from 'react-icons/lu'
import { FiMinus, FiMousePointer, FiSquare } from 'react-icons/fi'
import { IoHandRightOutline, IoText } from 'react-icons/io5'
import { PencilOptionsDropdown } from '../pencil-dropdown/pencil-dropdown'
import './action-bar-style.css'

ActionBar.propTypes = {
  tool: PropTypes.node.isRequired,
  setTool: PropTypes.func.isRequired,
  pencilOptions: PropTypes.func.isRequired,
  setPencilOptions: PropTypes.func.isRequired,
}

export function ActionBar({ tool, setTool, pencilOptions, setPencilOptions }) {
  const [showPencilOptions, setShowPencilOptions] = useState(false)
  const longPressTimer = useRef(null)

  const toggleShowPencilOptions = () => setShowPencilOptions(!showPencilOptions)

  const handleToolSelection = (selectedTool) => {
    // Clear any existing timer to prevent duplicates
    clearTimeout(longPressTimer.current)

    if (selectedTool === Tools.pencil) {
      // Set a timer that will trigger showing the options after 2 seconds
      longPressTimer.current = setTimeout(() => {
        toggleShowPencilOptions()
      }, 2)
    } else {
      setShowPencilOptions(false)
      setTool(selectedTool)
    }
  }

  const clearLongPress = () => {
    // Clear the timer when the mouse is released or the mouse leaves the button area
    clearTimeout(longPressTimer.current)
  }

  return (
    <div className="actionBar">
      {Object.values(Tools).map((t, index) => (
        <div
          className={`inputWrapper ${tool === t ? 'selected' : ''}`}
          key={t}
          onMouseDown={(e) => handleToolSelection(t, e)}
          onMouseUp={clearLongPress}
          onMouseLeave={clearLongPress}
          onTouchStart={(e) => handleToolSelection(t, e)}
          onTouchEnd={clearLongPress}
          onTouchCancel={clearLongPress}
        >
          <input
            type="radio"
            id={t}
            checked={tool === t}
            onChange={() => setTool(t)}
            readOnly
            className="actioninput"
          />

          <label className="actionlabel" htmlFor={t}>
            {t}
          </label>
          {t === 'upload' && <LuUpload />}
          {t === 'pan' && <IoHandRightOutline />}
          {t === 'selection' && <FiMousePointer />}
          {t === 'rectangle' && <FiSquare />}
          {t === 'line' && <FiMinus />}
          {t === 'pencil' && <LuBrush />}
          {t === 'text' && <IoText />}
          <span className="actionspan">{index + 1}</span>
        </div>
      ))}
      {tool === Tools.pencil && showPencilOptions && (
        <PencilOptionsDropdown options={pencilOptions} onOptionsChange={setPencilOptions} />
      )}
    </div>
  )
}
