import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { StyledHeader } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faExpandArrowsAlt, faCompressArrowsAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import Sidebar from './Sidebar'
import { useUI } from '../../contexts/UIContext'
import { useFullscreen } from '../../contexts/screen-context'

const Header = () => {
  const { showSidebar, setShowSidebar } = useUI()
  const [showTitle, setShowTitle] = useState('Home')
  const { logout, isAuthenticated } = useAuth0()
  const { isFullscreen, setIsFullscreen } = useFullscreen()

  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar)
  }

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen)
  }

  if (!isAuthenticated) {
    return null
  }

  return (
    <>
      <StyledHeader>
        <div className="space-x-4">
          <button onClick={toggleSidebar} className="text-3xl text-sky-400">
            <FontAwesomeIcon icon={faBars} />
          </button>
          <span className="text-4xl text-sky-500 font-semibold">{showTitle}</span>
        </div>
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} setShowTitle={setShowTitle} />
        <button onClick={toggleFullscreen} className="text-3xl text-sky-400">
          <FontAwesomeIcon icon={isFullscreen ? faCompressArrowsAlt : faExpandArrowsAlt} />
        </button>
        <button onClick={handleLogout} className="text-3xl text-sky-400">
          <FontAwesomeIcon icon={faSignOutAlt} /> {/* Assuming faSignOutAlt is imported for logout */}
        </button>
      </StyledHeader>
    </>
  )
}

export default Header
