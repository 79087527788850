import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Select, Button, Alert } from 'antd'
import { useAuth } from '../../contexts/auth-context'
import makeAuthorizedRequest from '../../core/api'

const { TextArea } = Input
const { Option } = Select

const CreateProjectForm = ({ isOpen, setIsOpen, project, onSave }) => {
  const { idToken } = useAuth()
  const [form] = Form.useForm() // Using antd Form's useForm hook
  const [projectData, setProjectData] = useState({
    name: '',
    description: '',
    thumbnail: '',
    status: 'Active',
    priority: 'High',
    deadline: '',
    budget: 0,
    teamMembers: [],
  })

  const [apiError, setApiError] = useState(null)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    if (project) {
      setProjectData({
        name: project.name || '',
        description: project.description || '',
        thumbnail: project.thumbnail || '',
        status: project.status || 'Active',
        priority: project.priority || 'High',
        deadline: project.deadline ? new Date(project.deadline).toISOString().slice(0, 16) : '',
        budget: project.budget || 0,
        teamMembers: project.teamMembers || [],
      })
      form.setFieldsValue({
        name: project.name || '',
        description: project.description || '',
        thumbnail: project.thumbnail || '',
        status: project.status || 'Active',
        priority: project.priority || 'High',
        deadline: project.deadline ? new Date(project.deadline).toISOString().slice(0, 16) : '',
        budget: project.budget || 0,
        teamMembers: project.teamMembers.map((member) => member.role) || [],
      })
    } else {
      setProjectData({
        name: '',
        description: '',
        thumbnail: '',
        status: 'Active',
        priority: 'High',
        deadline: '',
        budget: 0,
        teamMembers: [],
      })
      form.resetFields()
    }
  }, [project, form])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSelectChange = (name, value) => {
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleTeamMembersChange = (value) => {
    const roles = value.map((role, index) => ({
      userId: index + 1, // Example userId, replace with actual userId
      role,
    }))
    setProjectData((prevData) => ({
      ...prevData,
      teamMembers: roles,
    }))
  }

  const validateForm = () => {
    const newErrors = {}

    if (!projectData.name) newErrors.name = 'Project name is required'
    if (!projectData.description) newErrors.description = 'Project description is required'
    if (projectData.thumbnail && !projectData.thumbnail.startsWith('http'))
      newErrors.thumbnail = 'Invalid thumbnail URL'
    if (projectData.budget < 0) newErrors.budget = 'Budget cannot be negative'

    setFormErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async () => {
    if (!validateForm()) return

    const payload = {
      name: projectData.name,
      description: projectData.description,
      thumbnail: projectData.thumbnail,
      status: projectData.status,
      priority: projectData.priority,
      deadline: new Date(projectData.deadline).toISOString(), // Ensure the deadline is in ISO format
      budget: parseFloat(projectData.budget), // Ensure budget is a number
      teamMembers: projectData.teamMembers,
      createdAt: project ? project.createdAt : new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }

    const method = project ? 'PUT' : 'POST'
    const endpoint = project ? `/project?projectId=${project.id}` : '/project'

    try {
      const response = await makeAuthorizedRequest(endpoint, method, payload, idToken)

      console.log('Project saved:', response)
      setIsOpen(false)
      setApiError(null)
      onSave(response)
    } catch (error) {
      console.error('Error saving project:', error)
      setApiError('Failed to save project. Please try again later.')
    }
  }

  return (
    <Modal
      title={project ? 'Edit Project' : 'Create New Project'}
      visible={isOpen}
      onOk={handleSubmit}
      onCancel={() => setIsOpen(false)}
      footer={null}
      className="rounded-lg shadow-lg p-6 bg-white"
    >
      {apiError && <Alert message={apiError} type="error" showIcon className="mb-4" />}
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="space-y-4">
        <Form.Item
          label="Project Name"
          name="name"
          validateStatus={formErrors.name && 'error'}
          help={formErrors.name}
          className="text-left"
        >
          <Input name="name" value={projectData.name} onChange={handleInputChange} className="rounded-md" />
        </Form.Item>
        <Form.Item
          label="Project Description"
          name="description"
          validateStatus={formErrors.description && 'error'}
          help={formErrors.description}
          className="text-left"
        >
          <TextArea
            name="description"
            value={projectData.description}
            onChange={handleInputChange}
            className="rounded-md"
          />
        </Form.Item>
        <Form.Item
          label="Thumbnail URL"
          name="thumbnail"
          validateStatus={formErrors.thumbnail && 'error'}
          help={formErrors.thumbnail}
          className="text-left"
        >
          <Input name="thumbnail" value={projectData.thumbnail} onChange={handleInputChange} className="rounded-md" />
        </Form.Item>
        <Form.Item label="Project Priority" name="priority" className="text-left">
          <Select
            value={projectData.priority}
            onChange={(value) => handleSelectChange('priority', value)}
            className="rounded-md"
          >
            <Option value="High">High</Option>
            <Option value="Medium">Medium</Option>
            <Option value="Low">Low</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Deadline" name="deadline" className="text-left">
          <Input
            type="datetime-local"
            name="deadline"
            value={projectData.deadline}
            onChange={handleInputChange}
            className="rounded-md"
          />
        </Form.Item>
        <Form.Item
          label="Budget"
          name="budget"
          validateStatus={formErrors.budget && 'error'}
          help={formErrors.budget}
          className="text-left"
        >
          <Input
            type="number"
            name="budget"
            value={projectData.budget}
            onChange={(e) => setProjectData({ ...projectData, budget: parseFloat(e.target.value) })}
            className="rounded-md"
          />
        </Form.Item>
        <Form.Item label="Team Members" name="teamMembers" className="text-left">
          <Select
            mode="multiple"
            placeholder="Select roles"
            onChange={handleTeamMembersChange}
            value={projectData.teamMembers.map((member) => member.role)}
            className="rounded-md"
          >
            <Option value="Developer">Developer</Option>
            <Option value="Designer">Designer</Option>
            <Option value="Project Manager">Project Manager</Option>
            <Option value="Tester">Tester</Option>
          </Select>
        </Form.Item>
        <div className="flex justify-end space-x-2">
          <Button
            key="back"
            onClick={() => setIsOpen(false)}
            className="rounded-md bg-gray-300 hover:bg-gray-400 text-black"
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            className="rounded-md bg-blue-500 hover:bg-blue-600 text-white"
          >
            {project ? 'Update' : 'Submit'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

CreateProjectForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  project: PropTypes.object, // Prop to receive the project to be edited
  onSave: PropTypes.func.isRequired, // Callback function to handle saving the project
}

export default CreateProjectForm
