import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from '../../contexts/auth-context'
import makeAuthorizedRequest from '../../core/api'
import { FaHome, FaProjectDiagram, FaStar, FaChalkboard, FaUserCog, FaEdit } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import ProfileEditForm from '../../pages/profile/ProfileEditForm'

const Sidebar = ({ showSidebar, setShowSidebar, setShowTitle }) => {
  const [activeMenu, setActiveMenu] = useState('Home')
  const [showModal, setShowModal] = useState(false)
  const [projects, setProjects] = useState([])
  const [showPrompt, setShowPrompt] = useState(false)
  const sidebarRef = useRef(null)
  const navigate = useNavigate()
  const { idToken } = useAuth()
  const { user, logout, isAuthenticated } = useAuth0()
  const [userData, setUserData] = useState({
    user,
    logout,
    isAuthenticated,
  })

  const setMenu = (item) => {
    if (item === 'Edit') {
      if (projects.length === 0) {
        setShowPrompt(true)
      } else {
        navigate('/edit')
      }
    }
    setActiveMenu(item)
    setShowTitle(item)
  }

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await makeAuthorizedRequest('/project', 'GET', null, idToken)
        setProjects(Array.isArray(response) ? response : [])
      } catch (error) {
        console.error('Error fetching projects:', error)
        setProjects([])
      }
    }

    if (isAuthenticated && idToken) {
      fetchProjects()
    }
  }, [idToken, isAuthenticated])

  useEffect(() => {
    const checkOrCreateUser = async () => {
      try {
        // Check if the user exists
        const response = await makeAuthorizedRequest(`/user?email=${user.email}`, 'GET', null, idToken)
        console.log('response-->', response)
        if (response && response[0].email) {
          // If user exists, update userData with additional information
          setUserData((prevData) => ({
            ...prevData,
            user: {
              ...prevData.user,
              ...response[0], // Add user information
            },
          }))
        } else {
          // If user doesn't exist, create the user
          const userPayload = {
            username: user.nickname,
            email: user.email,
            firstName: user.name.split(' ')[0],
            lastName: user.name.split(' ')[1],
            status: 'Active',
            teamId: 1,
            roleName: 'Admin',
            themeId: 2,
            lastLogin: new Date().toISOString(),
          }

          await makeAuthorizedRequest('/user', 'POST', userPayload, idToken)

          console.log('User created successfully.')
        }
      } catch (error) {
        console.error('Error checking or creating user:', error) // Handle errors
      }
    }

    if (user && idToken) {
      console.log('idToken-->', idToken)
      console.log('user-->', user)
      checkOrCreateUser()
    }
  }, [user, setUserData])

  const navItems = [
    { path: '/', label: 'Home', Icon: FaHome },
    { path: '/edit', label: 'Edit', Icon: FaEdit },
    { path: '/projects', label: 'Projects', Icon: FaProjectDiagram },
    { path: '/canvas', label: 'Canvas', Icon: FaChalkboard },
    { path: '/favorites', label: 'Favorites', Icon: FaStar },
    { path: '/settings', label: 'Settings', Icon: FaUserCog },
  ]

  useEffect(() => {
    // Hide the sidebar when 'Studio' is selected
    if (activeMenu === 'studio') {
      setShowSidebar(false)
    }
  }, [activeMenu, setShowSidebar])

  return (
    <div
      ref={sidebarRef}
      className={`${
        showSidebar ? 'translate-x-0' : '-translate-x-full'
      } fixed inset-y-1 z-50 w-60 rounded-lg left-2 top-2 bottom-2 bg-gray-700/[0.8] transition-transform duration-100 ease-out`}
    >
      <div className="flex flex-col justify-between h-full">
        <div className="p-5">
          <div className="bg-gray-800/[0.2] rounded-lg">
            <Link to="/">
              <img src="logo/short_circuit.png" alt="Logo" className="w-auto h-45 mb-10 dim" />
            </Link>
          </div>
          {isAuthenticated && user ? (
            <div className="profile-section bg-gray-700/[0.2] p-3 rounded-lg mt-5 relative">
              <div className="flex">
                {/* Profile Image Column */}
                <div className="w-2/5">
                  <img src={user.picture} alt="Profile" className="w-full h-auto aspect-square rounded-full" />
                </div>

                {/* Information Column */}
                <div className="w-3/5 flex flex-col justify-center ml-3">
                  <h5 className="text-white text-lg">{user.name}</h5>
                  <p className="text-white text-sm">{user.email}</p>
                </div>
              </div>

              {/* Edit Profile Icon */}
              <button
                className="absolute top-0 right-0 rounded-lg text-blue-400 hover:text-blue-300"
                onClick={() => setShowModal(true)}
                style={{ background: 'rgba(255, 255, 255, 0)', padding: '8px' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.0"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 7.125l.825-.825a2.25 2.25 0 113.182 3.182l-.825.825m-2.598-2.598l-8.25 8.25h-3.75v-3.75l8.25-8.25zm0 0L6.75 18.375v.75h.75L15.75 10.5m0-3.375V5.25H5.25v10.5h4.5"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <p className="text-white">Loading user information...</p>
          )}
          <ul>
            {navItems.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.path}
                  className={`flex items-center p-2 text-white hover:bg-gray-700 rounded ${
                    activeMenu === item.label ? 'bg-gray-700/[0.5]' : ''
                  }`}
                  onClick={() => setMenu(item.label)}
                >
                  <item.Icon className="mr-2" />
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <p className="text-center text-white text-sm p-4">© {new Date().getFullYear()} MS-RT All Rights Reserved</p>
      </div>
      <ProfileEditForm userData={userData} showModal={showModal} setShowModal={setShowModal} />
      {showPrompt && (
        <div className="fixed inset-0 bg-gray-800/[0.5] flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold mb-4">No Projects Available</h3>
            <p className="mb-4">Please add a project before editing.</p>
            <button
              onClick={() => {
                setShowPrompt(false)
                navigate('/projects')
              }}
              className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
            >
              Add New Project
            </button>
            <button
              onClick={() => setShowPrompt(false)}
              className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 ml-4"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

Sidebar.propTypes = {
  showSidebar: PropTypes.node.isRequired,
  setShowSidebar: PropTypes.func.isRequired,
  setShowTitle: PropTypes.func.isRequired,
}

export default Sidebar
