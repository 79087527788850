import React from 'react'
/* eslint-disable react/prop-types */
const RefineTab = ({ upscaleFactor, setUpscaleFactor }) => {
  return (
    <div>
      <label className="block text-sm font-medium mb-1">Upscale:</label>
      <div className="flex space-x-2 mb-4">
        {[1, 2, 3, 4].map((scale) => (
          <button
            key={scale}
            className={`w-8 h-8 flex border-2 items-center justify-center rounded-md ${
              upscaleFactor === scale ? 'bg-gray-400' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setUpscaleFactor(scale)}
          >
            {`${scale}x`}
          </button>
        ))}
      </div>
    </div>
  )
}

export default RefineTab
