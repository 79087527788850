import getStroke from 'perfect-freehand'
import { ElementType } from '../types.ts'

export const drawElement = (
  // TODO: add type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roughCanvas: any,
  context: CanvasRenderingContext2D,
  element: ElementType,
) => {
  switch (element.type) {
    case 'line':
    case 'rectangle':
      roughCanvas.draw(element.roughElement)
      break
    case 'upload': {
      break
    }
    case 'pencil': {
      drawPencil(context, element)
      break
    }
    case 'text': {
      context.textBaseline = 'top'
      context.font = '24px sans-serif'
      const text = element.text || ''
      context.fillText(text, element.x1, element.y1)
      break
    }
    default:
      throw new Error(`Type not recognised: ${element.type}`)
  }
}

const drawPencil = (context, element) => {
  if (!element.points) {
    throw new Error('Pencil element points are undefined')
  }

  context.globalAlpha = element.opacity
  context.strokeStyle = element.color
  context.lineWidth = element.size
  context.lineJoin = 'round'
  context.lineCap = 'round'

  const strokeOptions = {
    size: element.size,
    thinning: element.thinning || 0.7,
    smoothing: element.smoothing || 0.5,
    streamline: element.streamline || 0.5,
    simulatePressure: true,
  }

  // Adjust lineWidth based on pressure
  context.lineWidth = element.size * (element.pressure || 1) // Add this line

  const strokePoints = getStroke(element.points, strokeOptions)

  // Ensure strokePoints are in the correct format
  if (!Array.isArray(strokePoints) || !strokePoints.length) {
    throw new Error('Invalid stroke points returned from getStroke')
  }

  const formattedPoints: [number, number][] = strokePoints.slice(1, -1).map((point) => {
    if (point.length !== 2) {
      throw new Error(`Expected point to have exactly 2 elements, got ${point.length}`)
    }
    return [point[0], point[1]]
  })
  // Check for an empty stroke
  if (formattedPoints.length === 0) return

  const stroke = getSvgPathFromStroke(formattedPoints)

  // If the stroke path is invalid, do not attempt to draw
  if (!stroke) return

  const path = new Path2D(stroke)
  context.fillStyle = element.color // Sets the fill color
  path.closePath() // Optionally close the path for filling
  context.fill(path)
}

// 🥑 source: https://www.npmjs.com/package/perfect-freehand/v/1.0.4
const getSvgPathFromStroke = (stroke: [number, number][]) => {
  if (!stroke.length) return ''

  const d = stroke.reduce(
    (acc: string[], [x0, y0]: [number, number], i: number, arr: [number, number][]) => {
      const [x1, y1] = arr[(i + 1) % arr.length]
      acc.push(x0.toString(), y0.toString(), ((x0 + x1) / 2).toString(), ((y0 + y1) / 2).toString())
      return acc
    },
    ['M', ...stroke[0].map((num) => num.toString()), 'Q'],
  )

  d.push('Z')
  return d.join(' ')
}
